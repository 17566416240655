export default [
    {
      key: "lead_name",
      label: "Name",
      thClass: "text-left",
      visible: true,
    },
    {
      key: "requested_by_name",
      label: "Requested by",
      thClass: "text-left",
      visible: true,
    },
    {
      key: "completed_by_name",
      label: "Completed by",
      thClass: "text-left",
      visible: true,
    },
    {
      key: "files",
      label: "Files",
      thClass: "text-center",
      visible: true,
    },
    {
      key: "auth",
      label: "Auth",
      thClass: "text-center",
      visible: true,
    },
    {
      key: "pdf",
      label: "PDF",
      thClass: "text-center",
      visible: true,
    },
    {
      key: "actions",
      label: "Actions",
      thClass: "text-center",
      tdClass: "p-1/2 ",
      visible: true,
    },
  ];
  