<template>
  <b-modal
    size="lg"
    ref="upload-view-lead-realtor-cr"
    modal-class="modal-primary"
    :title="realtorRequest.hasFiles == 0 ? 'Upload CR' : 'View Files'"
    title-tag="h3"
    @hidden="$emit('hidden')"
    :hide-footer="realtorRequest.hasFiles == 1"
  >
    <account-client-header :client="realtorRequest.lead_name" />
    <b-row>
      <b-col cols="12" v-if="realtorRequest.hasFiles == 0">
        <drag-and-drop
          ref="file-manager"
          v-model="uploadFiles"
          :files-array="uploadFiles"
          :single="true"
        />
      </b-col>
      <b-col cols="12" v-else>
        <b-table
          :items="realtorFiles"
          :fields="fieldsTable"
          class="fs-6 mt-1"
          show-empty
          small
        >
          <template #cell(name_file)="data">
            <a :href="data.item.url" target="_blank">
              {{ data.item.name_file }}
            </a>
          </template>
          <template #cell(created_at)="data">
            <span>
              {{ data.item.created_at | myGlobalWithHour }}
            </span>
          </template>
        </b-table>
      </b-col>
      <b-col cols="12">
        <h5>Observation of specialist</h5>
        <b-form-textarea
          v-model="recommendation"
          rows="5"
          :disabled="realtorRequest.hasFiles == 1"
        />
      </b-col>
    </b-row>
    <br />
    <template #modal-footer>
      <div class="w-100">
        <span class="fs-2 float-left mt-1" v-if="realtorRequest.hasFiles == 1">
          Upload by:
          <b-badge class="px-1" style="font-size: 1rem" variant="info">
            {{updatedByName}}
          </b-badge>
        </span>
        <b-button v-else variant="primary" @click="sendCrRealtor()"
          >Upload</b-button
        >
      </div>
    </template>
  </b-modal>
</template>
<script>
//components
import AccountClientHeader from "@/views/commons/components/applications/views/components/others/AccountClientHeader.vue";
import DragAndDrop from "@/views/commons/utilities/DragAndDrop.vue";
//store
import { mapGetters } from "vuex";
//services
import NcrRealtorLeadsService from "@/views/specialist-digital/views/cr-realtor/service/ncr-realtor-leads.service";
//mixins
import ModalMixin from "@/mixins/modal";
export default {
  components: {
    AccountClientHeader,
    DragAndDrop,
  },
  props: {
    realtorRequest: {
      type: Object,
      required: true,
    },
  },
  mixins: [ModalMixin],
  async created() {
    if (this.realtorRequest.hasFiles == 1) {
      await this.getLeadFilesPdf();
      this.removePreloader();
    }
  },
  mounted() {
    this.toggleModal("upload-view-lead-realtor-cr");
  },
  data() {
    return {
      isVisible: false,
      uploadFiles: [],
      client: {
        account: "",
        name: "",
        realtor_id: "",
        account_id: "",
      },
      file: null,
      myData: {},
      fieldsTable: [
        {
          key: "name_file",
          label: "Name",
        },
        {
          key: "size",
          label: "Size",
        },
        {
          key: "created_at",
          label: "Upload At",
        },
      ],
      obs: "",
      recommendation: "",
      realtorFiles: [],
      updatedByName: "",
    };
  },
  methods: {
    async sendCrRealtor() {
      try {
        this.addPreloader();
        const formData = new FormData();
        formData.append("ncr_realtor_request_id", this.realtorRequest.id);
        formData.append("file", this.uploadFiles[0]);
        formData.append("recommendation", this.recommendation);
        formData.append("document_type", 2);
        formData.append("created_by", this.currentUser.user_id);

        await NcrRealtorLeadsService.insertPdfToNcrRealtorLead(formData);
        this.showSuccessSwal();
      } catch (error) {
        console.error(error);
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
        this.$emit("onSendCrRealtor", this.realtorRequest, this.recommendation);
      }
    },
    async getLeadFilesPdf() {
      const data = await NcrRealtorLeadsService.getNcrRealtorFileLeads({
        ncr_realtor_request_id: this.realtorRequest.id,
        status: 2,
      });
      this.realtorFiles = data;
      if (this.realtorFiles.length > 0) {
        this.recommendation = this.realtorFiles[0].recommendation;
        this.updatedByName = this.realtorFiles[0].created_by_name;
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
};
</script>