import { amgApi } from "@/service/axios"

class NcrRealtorLeadsService {
  async getLeadNcrRealtorSpecialists(params) {
    try {
      const { data } = await amgApi.post(
        "/credit-experts-digital/sales/get-lead-ncr-realtor-specialists",
        params
      )
      return data
    } catch (error) {
      throw error
    }
  }
  async getNcrRealtorFileLeads(params) {
    try {
      const { data } = await amgApi.post(
        "/credit-experts-digital/sales/get-ncr-realtor-file-leads",
        params
      )
      return data
    } catch (error) {
      throw error
    }
  }
  async generateBorrowerSignaturePdfLead(params) {
    try {
      const data = await amgApi.post(
        "/credit-experts-digital/sales/generate-borrowed-signature-auth-pdf-lead",
        params,
        { responseType: "blob" }
      )
      return data
    } catch (error) {
      throw error
    }
  }
  async insertPdfToNcrRealtorLead(params) {
    try {
      const data = await amgApi.post(
        "/credit-experts-digital/sales/insert-pdf-to-ncr-realtor-lead",
        params
      )
      return data
    } catch (error) {
      throw error
    }
  }
  async doneRealtorLeadRequest(params) {
    try {
      const data = await amgApi.post(
        "/credit-experts-digital/sales/done-realtor-lead-request",
        params
      )
      return data
    } catch (error) {
      throw error
    }
  }
}

export default new NcrRealtorLeadsService()
