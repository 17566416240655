<template>
  <div>
    <filter-slot
      :filter="filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['refLeadsGridRealtorCR'].refresh()"
    >
      <template #table>
        <b-table
          ref="refLeadsGridRealtorCR"
          no-border-collapse
          class="position-relative"
          :fields="visibleFields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          primary-key="id"
          responsive="sm"
          :items="search"
          :sort-by.sync="sortByChange"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(lead_name)="data">
            <b-col>
              <b-row>
                {{ data.item.lead_name }}
              </b-row>
              <b-row>
                <div>{{ data.item.lead_mobile }}</div>
              </b-row>
            </b-col>
          </template>
          <template #cell(completed_by_name)="data">
            <b-col>
              <b-row>{{ data.item.completed_by_name }}</b-row>
              <b-row>{{ data.item.completed_at | myGlobalWithHour }}</b-row>
            </b-col>
          </template>
          <template #cell(requested_by_name)="data">
            <b-col>
              <b-row>{{ data.item.requested_by_name }}</b-row>
              <b-row>{{ data.item.created_at | myGlobalWithHour }}</b-row>
            </b-col>
          </template>
          <template #cell(files)="data">
            <center>
              <feather-icon
                icon="FolderIcon"
                class="cursor-pointer"
                style="color: var(--warning)"
                size="20"
                @click="openModalFilesLead(data.item)"
              />
            </center>
          </template>
          <template #cell(auth)="data">
            <center>
              <a target="_blank" @click="exportToPdf(data.item.lead_id)">
                <amg-icon icon="FilePdfIcon" size="20" />
              </a>
            </center>
          </template>
          <template #cell(pdf)="data">
            <center>
              <feather-icon
                :icon="data.item.hasFiles ? 'EyeIcon' : 'UploadIcon'"
                :class="[
                  'cursor-pointer',
                  data.item.hasFiles ? 'text-success' : 'text-primary',
                ]"
                size="20"
                @click="openModalUploadFilesLead(data.item)"
              />
            </center>
          </template>
          <template #cell(actions)="data">
            <div class="d-flex justify-content-center">
              <b-button
                pill
                variant="success"
                class="text-center widthClients text-light"
                :disabled="data.item.hasFiles == 0"
                @click="openModalDoneRealtorLead(data.item)"
              >
                <feather-icon
                  icon="CheckIcon"
                  size="15"
                  :class="isDarkSkin ? 'text-white mr-1' : 'text-white mr-1'"
                />DONE
              </b-button>
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>
    <modal-files-lead-realtor
      v-if="openModalFilesLeadRealtor"
      @hidden="openModalFilesLeadRealtor = false"
      :realtor-request="selectedRequest"
    />
    <modal-upload-lead-cr-realtor
      v-if="openModalUploadLeadCrRealtor"
      @hidden="openModalUploadLeadCrRealtor = false"
      @onSendCrRealtor="onSendCrRealtor"
      :realtor-request="selectedRequest"
    />
    <modal-done-realtor-lead
      v-if="modalDoneRealtorLead"
      @hidden="modalDoneRealtorLead = false"
      :realtor-request="selectedRequest"
      @onDoneRealtorLeadRequest="onDoneRealtorLeadRequest"
    />
  </div>
</template>

<script>
import dataFields from "@/views/specialist-digital/views/cr-realtor/components/realtor-lead.fields.js";
import dataFilters from "@/views/specialist-digital/views/cr-realtor/components/filters.data.js";
import IconStatusAccount from "@/views/commons/components/ncr-realtor/views/components/icons/IconStatusAccount.vue";
import ModalTracking from "@/views/commons/components/ncr-realtor/views/components/modals/ModalTrackingDigitalSpecialist.vue";
import UploadRealtorCr from "@/views/specialist-digital/views/cr-realtor/components/AddFileDragAndDrop.vue";
import ModalDoneRealtor from "@/views/specialist-digital/views/cr-realtor/components/ModalDoneRealtor.vue";
import ModalAddWorkPlan from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/ModalAddWorkPlan.vue";
import AddRoundModal from "@/views/specialist-digital/views/clients/components/AddRoundModal";
import { mapGetters } from "vuex";
import BadgeRiskyClients from "@/views/commons/components/risky-clients/BadgeRiskyClients";
import NcrRealtorLeadsService from "@/views/specialist-digital/views/cr-realtor/service/ncr-realtor-leads.service";
import ModalFilesLeadRealtor from "@/views/specialist-digital/views/cr-realtor/components/modal/ModalFilesLeadRealtor.vue";
import ModalUploadLeadCrRealtor from "@/views/specialist-digital/views/cr-realtor/components/modal/ModalUploadLeadCrRealtor.vue";
import ModalDoneRealtorLead from "@/views/specialist-digital/views/cr-realtor/components/modal/ModalDoneRealtorLead.vue";
export default {
  components: {
    IconStatusAccount,
    ModalTracking,
    UploadRealtorCr,
    ModalAddWorkPlan,
    ModalDoneRealtor,
    AddRoundModal,
    BadgeRiskyClients,
    ModalFilesLeadRealtor,
    ModalUploadLeadCrRealtor,
    ModalDoneRealtorLead,
  },
  data() {
    return {
      startPage: 0,
      toPage: 0,
      totalRows: 1,
      sortBy: "created_at",
      sortDesc: true,
      perPageOptions: [10, 25, 50, 100],
      paginate: {
        currentPage: 1,
        perPage: 100,
      },
      itemClient: {
        id: null,
        account: "",
        clientName: "",
      },
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Name of lead...",
        model: "",
      },
      isBusy: false,
      addRoundModal: false,
      fields: dataFields,
      items: [],
      filters: dataFilters,
      activeModalTracking: false,
      activeModalUploadCr: false,
      client: {
        name: "",
        account: "",
        realtor_id: null,
        account_id: "",
        read_status: null,
        // Pendin Process CR
        id: 0,
        lead_id: 0,
      },
      workPlanData: {},
      workPlanController: false,
      isModalDoneRealtor: false,
      openModalFilesLeadRealtor: false,
      openModalUploadLeadCrRealtor: false,
      modalDoneRealtorLead: false,
      selectedRequest: {},
    };
  },
  computed: {
    visibleFields() {
      return this.fields.filter((item) => item.visible);
    },
    isCompleted() {
      return !!this.$route.meta.statusCR;
    },
    sortByChange() {
      return this.$route.meta.statusCR ? "date_completed" : "created_at";
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() {
    if (this.$route.meta.statusCR == 2) {
      this.fields.find((item) => item.key == "actions").visible = false;
      this.fields.find(
        (item) => item.key == "completed_by_name"
      ).visible = true;
    } else {
      this.fields.find((item) => item.key == "actions").visible = true;
      this.fields.find(
        (item) => item.key == "completed_by_name"
      ).visible = false;
    }
  },
  methods: {
    async exportToPdf(lead_id) {
      this.addPreloader();
      try {
        const data =
          await NcrRealtorLeadsService.generateBorrowerSignaturePdfLead({
            lead_id,
          });
        if (data.status === 200) {
          await this.forceFileDownloadPdf(data.data);
        }
        this.removePreloader();
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
    onSendCrRealtor(item, recommendation) {
      this.$set(item, "hasFiles", 1);
      this.$set(item, "recommendation", recommendation);
      this.openModalUploadLeadCrRealtor = false;
    },
    onDoneRealtorLeadRequest() {
      this.modalDoneRealtorLead = false;
      this.$refs.refLeadsGridRealtorCR.refresh();
    },
    async search(ctx) {
      try {
        const params = {
          searchText: this.filterPrincipal.model,
          page: ctx.currentPage,
          perPage: ctx.perPage,
          orderDirection: ctx.sortDesc == 1 ? "desc" : "asc",
          orderBy: "created_at",
          status: this.$route.meta.statusCR,
          from: this.filters[0].model,
          to: this.filters[1].model,
        };
        const data = await NcrRealtorLeadsService.getLeadNcrRealtorSpecialists(
          params
        );
        this.items = data.data;
        this.startPage = data.from;
        this.paginate.currentPage = data.current_page;
        this.paginate.perPage = data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.last_page;
        this.totalRows = data.total;
        this.toPage = data.to;
        return this.items || [];
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    openModalFilesLead(item) {
      this.openModalFilesLeadRealtor = true;
      this.selectedRequest = item;
    },
    openModalUploadFilesLead(item) {
      this.openModalUploadLeadCrRealtor = true;
      this.selectedRequest = item;
    },
    openModalDoneRealtorLead(item) {
      this.modalDoneRealtorLead = true;
      this.selectedRequest = item;
    },
  },
};
</script>

<style>
#icon {
  border-radius: 50%;
  margin-bottom: 2px;
  margin-right: 5px;
}
.green {
  color: #00cc00;
  border-color: #00cc00;
  background: #00cc00;
}
.blue {
  color: #0066ff;
  border-color: #00cc00;
  background: #0066ff;
}
.red {
  color: red;
  border-color: red;
  background: red;
}
.yellow {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 14px solid #ffc107;
}
</style>
