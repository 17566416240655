<template>
  <b-modal
    size="md"
    ref="modal-files-lead-realtor"
    modal-class="modal-primary"
    title="Lead's Files"
    title-tag="h3"
    @hidden="$emit('hidden')"
    :hide-footer="true"
  >
    <account-client-header :client="realtorRequest.lead_name" />
    <b-row>
      <b-col>

        <b-table
          :items="leadFiles"
          :fields="fieldsTable"
          class="mt-1"
          show-empty
          small
        >
          <template #cell(name_file)="data">
            <a :href="data.item.url" target="_blank">
              {{ data.item.name_file }}
            </a>
          </template>
          <template #cell(created_at)="data">
            <span v-if="data.value">
              {{ data.item.created_at | myGlobalWithHour }}
            </span>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <br />
  </b-modal>
</template>
<script>
//components
import AccountClientHeader from "@/views/commons/components/applications/views/components/others/AccountClientHeader.vue";
//store
import { mapGetters } from "vuex";
import NcrRealtorLeadsService from "@/views/specialist-digital/views/cr-realtor/service/ncr-realtor-leads.service";
import ModalMixin from "@/mixins/modal";
export default {
  components: {
    AccountClientHeader,
  },
  props: {
    realtorRequest: {
      type: Object,
      required: true,
    },
  },
  mixins: [ModalMixin],
  data() {
    return {
      isVisible: false,
      client: {
        account: "",
        name: "",
        realtor_id: "",
        account_id: "",
      },
      file: null,
      myData: {},
      fieldsTable: [
        {
          key: "name_file",
          label: "Name",
        },
        {
          key: "size",
          label: "Size",
        },
        {
          key: "created_at",
          label: "Upload At",
        },
      ],
      obs: "",
      recomendation: "",
      leadFiles: [],
    };
  },
  methods: {
    async getLeadFiles() {
      const data = await NcrRealtorLeadsService.getNcrRealtorFileLeads({
        ncr_realtor_request_id: this.realtorRequest.id,
        status: 1
      });
      this.leadFiles = data;
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  async created() {
    await this.getLeadFiles();
    this.removePreloader();
  },
  mounted(){
    this.toggleModal('modal-files-lead-realtor')
  }
};
</script>