<template>
  <div>
    <b-modal
      ref="modal-done"
      centered
      size="lg"
      no-close-on-backdrop
      modal-class="modal-primary"
      title="Done Realtor"
      title-tag="h3"
      @hidden="$emit('hidden')"
    >
      <!-- HEADER START -->
      <account-client-header :client="realtorRequest.lead_name" />
      <b-container>
        <b-row>
          <b-col cols="2" class="ml-1 bg-primary rounded-top paddingTop">
            <h6 class="text-white text-center">OBSERVATION</h6>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-textarea
              id="textarea"
              v-model="obs"
              placeholder="Enter something..."
              rows="3"
              max-rows="6"
            />
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <b-button @click="$emit('hidden')"> Cancel </b-button>
        <b-button variant="primary" @click="doneRealtorLeadRequest()"> Save </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { ValidationObserver } from "vee-validate";
import ModalHeader from "@/views/specialist-digital/views/process-cr/view/components/modals/ModalHeader.vue";
import AccountClientHeader from "@/views/commons/components/applications/views/components/others/AccountClientHeader.vue";
import ModalMixin from "@/mixins/modal";
import NcrRealtorLeadsService from "@/views/specialist-digital/views/cr-realtor/service/ncr-realtor-leads.service";
export default {
  components: {
    ModalHeader,
    ValidationObserver,
    AccountClientHeader,
  },
  props: {
    realtorRequest: {
      type: Object,
    },
  },
  mixins: [ModalMixin],
  data() {
    return {
      obs: "",
      client: {
        account: "",
        name: "",
        realtor_id: "",
        account_id: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() {
    this.toggleModal("modal-done");
    this.obs = this.realtorRequest.recommendation;
  },
  methods:{
    async doneRealtorLeadRequest(){
        try {
            this.addPreloader();
            await NcrRealtorLeadsService.doneRealtorLeadRequest({
                ncr_realtor_request_id: this.realtorRequest.id,
                recommendation: this.obs,
                completed_by: this.currentUser.user_id,
                lead_id: this.realtorRequest.lead_id
            })    
            this.showSuccessSwal();
            this.$emit('onDoneRealtorLeadRequest')
        } catch (error) {
            console.log(error)
            this.showErorSwal(error);
        } finally {
            this.removePreloader();
        }
    }
  }
};
</script>
<style scoped>
.paddingTop {
  padding-top: 12px;
}
</style>
